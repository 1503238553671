import React, { useState, useEffect } from 'react'
import Button from '../../../shared/Button'
import Modal from '../../../shared/Modal'
import Section from '../../Section'

const ModalButton = ({ buttonText, ...modalContent }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  useEffect(() => {
    window.addEventListener('popstate', closeModal)
    return () => window.removeEventListener('popstate', closeModal)
  }, [])

  const openModal = () => {
    setIsModalOpen(true)
    window.history.pushState('', null, '#modal')
  }

  const navigateBack = () => {
    window.history.back()
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  return (
    <>
      <p>
        <Button onClick={openModal}>{buttonText}</Button>
      </p>
      <Modal open={isModalOpen} onClose={navigateBack}>
        <Section {...modalContent} />
      </Modal>
    </>
  )
}

export default ModalButton
